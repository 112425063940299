import { ClickAwayListener } from '@material-ui/core'
import moment from 'moment'
import React, { Component } from 'react'
export default class IDCardFrontViewMob extends Component {
  constructor(props) {
    super(props)
    this.state = {
      network: this.props.network,
      digitalcarddata: this.props.data,
      planinfo: this.props.data.memberIdCardList[0].planInfo,
      plainId: this.props.data.memberIdCardList[0].planId,
      prefix: '',
      groupno: '',
      contactno: this.props.contactNumber,
      //   day:moment(this.props.data.memberIdCardList[0].enrollmentDate).format('DD'),
      //   month:moment(this.props.data.memberIdCardList[0].enrollmentDate).format('MM'),
      //   year:moment(this.props.data.memberIdCardList[0].enrollmentDate).format('YYYY'),
      membersince: ''
      //    + this.state.day+this.state.year,
    }
    this.printparentcall = this.printparentcall.bind(this)
    console.log('MOB FRONT PROPS=================', this.props)
  }

  componentDidMount() {
    this.dateformat()
    console.log('FIND PREFIX DARA', this.props.data.memberIdCardList[0].planInfo)
    this.state.planinfo.map((data, index) => {
      if (data.idcardField == 'prefix') {
        this.setState({ prefix: data.fieldValue })
      }
      if (data.idcardField == 'group') {
        this.setState({ groupno: data.fieldValue })
      }
      if (data.idcardField == 'contact number') {
        this.setState({ contactno: data.fieldValue })
      }
    })
  }

  getPrefix = (data) => {
    console.log('emp--id-- ', this.props.showEmpId);
    let prefix = this.state.prefix;
    // if (this.props.showEmpId && data.empId && data.empId.length == 9) {
    //   prefix = "TEMP";
    // }
    return prefix;
  };

  getDateInUTC = (date, getInMillisecs) => {
    if (date) {
      let newDateTime = date + new Date(date).getTimezoneOffset() * 60 * 1000

      if (getInMillisecs) {
        return newDateTime
      }

      return new Date(newDateTime)
    }

    return date
  }

  dateformat() {
    let enrollmentDate = this.getDateInUTC(this.props.data.memberIdCardList[0].enrollmentDate, true)

    var day = moment(enrollmentDate).format('DD')

    var mon = moment(enrollmentDate).format('MM')

    var year = moment(enrollmentDate).format('YYYY')

    var date = mon + '/' + day + '/' + year

    this.setState({ membersince: date })
  }

  // dateformat(){
  //     var day=moment(this.props.data.memberIdCardList[0].enrollmentDate).format('DD')
  //     var mon=moment(this.props.data.memberIdCardList[0].enrollmentDate).format('MM')
  //     var year=moment(this.props.data.memberIdCardList[0].enrollmentDate).format('YYYY')
  //     var date=mon+"/"+day+"/"+year
  //     this.setState({membersince:date})
  //   }

  printparentcall() {
    this.props.printCardView()
  }
  render() {
    return (
      <div class="fview">
        <div class="fv_background_image">
          {/* =============Top Imaghe container========= */}
          <div class="fv_top_image_container">
            <img src={require('../../Images/Card/UHS_Logo_RGB_600px_200px.png')} class="fv_left_logo img-fluid" />

            {this.props.network == 'AFMC' ? (
              <img src={require('../../Images/Card/AZFMC_logo.png')} class="fv_right_logo  img-fluid" />
            ) : null}

            { (this.props.network == 'PHCS' || this.props.network == 'THS' || this.props.network == 'Smartshare') ? (
              <img src={require('../../Images/Card/PHCS_logo.png')} class="fv_right_logo  img-fluid" />
            ) : null}
            {/* <img src={require('../../Images/Card/image-4.png')} class="fv_right_logo  img-fluid" /> */}
          </div>

          <div class="fv_card ">
            {/* =============ledt container========= */}
            <div class="fv_left_container">
              {this.state.digitalcarddata &&
                this.state.digitalcarddata.memberIdCardList.map((data, index) => (
                  <div>
                    {index == 0 ? (
                      <div>
                        <div class="fv_adult_top_name">
                          {data.firstName} {data.lastName}
                        </div>
                        <div class="fv_username">
                          {/* {this.state.prefix}{this.props.showEmpId ? data.empId : data.memberId}  */}
                          {localStorage.getItem('SOURCE') === 'NEO' ? (
                            data.memberId
                          ) : (
                            <>
                            {this.getPrefix(data)}
                            {this.props.showEmpId ? data.empId : data.memberId}
                              {/* {this.state.prefix}
                              {data.empId} */}
                            </>
                          )}
                        </div>
                      </div>
                    ) : null
                    //   <div class="fv_list_view">
                    //   <div class="fv_list_adultname">{data.firstName} {data.lastName}</div>
                    //   <div class="fv_list_username">{data.memberId}</div>
                    // </div>
                    }
                  </div>
                ))}

              <div class="mob_scrollbarview">
                {this.state.digitalcarddata &&
                  this.state.digitalcarddata.memberIdCardList.map((data, index) => (
                    <div>
                      {index != 0 ? (
                        <div class="fv_list_view">
                          <div class="fv_list_adultname">
                            {data.firstName} {data.lastName}
                          </div>
                          <div class="fv_list_username">
                          {this.getPrefix(data)}
                            {/* {this.state.prefix} */}
                            {this.props.showEmpId ? data.empId : data.memberId}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
              </div>
            </div>

            {/* =============Right Conrtainer========= */}
            <div class="fv_right_container" style={this.state.network == 'Smartshare' ? { marginTop: '0' } : { marginTop: '1.2%' }}>
              <div class="fv_joined_text">Program Details</div>

              <div class="fv_right_table1">
                <div class="fv_left_table">
                  <div class="fv_table_left_text">Member Since</div>
                  <div class="fv_table_left_text">Group No.</div>
                  <div class="fv_table_left_text">Program ID</div>
                </div>
                <div class="fv_right_table">
                  <div class="fv_table_right_text">{this.state.membersince}</div>
                  <div class="fv_table_right_text">{this.state.groupno ? this.state.groupno : "NA"}</div>
                  <div class="fv_table_right_text">{this.state.digitalcarddata.memberIdCardList[0].planId}</div>
                </div>
              </div>

              <div class="fv_space_2table"></div>

              {/* {this.props.network =='Smartshare' ? (
                    <div class="fv_table_middle_text">Sharing for all medical services is limited to $27,500/- per Member/per year</div>
                  ) : (
                    <div class="fv_space_2table"></div>
                  )} */}

              {/* ====================Dyanmic content for all user========== */}
           {(localStorage.getItem("planId")!=="10051" || localStorage.getItem("planId")!=="10052" || localStorage.getItem("planId")!=="10053" || localStorage.getItem("planId")!=="10061" || localStorage.getItem("planId")!=="10062" || localStorage.getItem("planId")!=="10063") &&  <div> {this.state.network == 'Smartshare' ? (
                <div>
                  {this.state.digitalcarddata &&
                    this.state.digitalcarddata.memberIdCardList.map((data, index) => (
                      <div>
                        {data.idcardField != 'group' &&
                        data.idcardField != 'prefix' &&
                        data.idcardField != 'card id' &&
                        data.idcardField != 'Chiropractic' &&
                        data.idcardField != 'Surgery EasyShare Limit per surgery' &&
                        data.idcardField != 'contact number' &&
                        data.idcardField != 'Program Details' &&
                        index == 0 ? (
                          <div>
                            <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">Telemedicine</div>

                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField === 'Telemedicine')[0].fieldValue}
                              </div>
                            </div>
                            <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text" style={{width: '42%'}}>Primary Care/Pediatrics</div>

                              <div className="fv_new_table_right_text" style={{marginTop: '3px'}}>
                                {data.planInfo.filter(res => res.idcardField === 'Primary Care/Pediatrics')[0].fieldValue}
                              </div>
                            </div>
                            <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">Speciality Care</div>

                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField.toLowerCase() === 'specialty care')[0].fieldValue}
                              </div>
                            </div>
                            <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">CT Scan/MRI</div>

                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField.trim() === 'CT scan/MRI')[0].fieldValue}
                              </div>
                            </div>
                            <div class="fv_space_2table2"></div>
                            <div style={{ display: 'inline-flex' }}>
                              <div className="fv_new_table_left_text1" style={{ fontWeight: 'bold', flex: '0 0 auto', flexWrap:'wrap', width:'50%', marginTop: '15px' }}>
                                Member Annual Sharing Limits
                              </div>

                              <div className="fv_new_table_right_text" style={{ marginLeft: '5%', paddingTop:20 }}>
                                {
                                  data.planInfo.filter(res => res.idcardField === 'Member service annual sharing annual service limits')[0]
                                    .fieldValue
                                }
                              </div>
                            </div>
                            <div className="fv_right_new_tablebottom">
                              <div className="fv_new_table_left_textbottom">ER Visit</div>

                              <div className="fv_new_table_right_textbottom" style={{ marginLeft: '13.8%' }}>
                                {data.planInfo.filter(res => res.idcardField === 'ER Visit')[0].fieldValue}
                              </div>
                            </div>
                            <div className="fv_right_new_tablebottom1">
                              <div className="fv_new_table_left_textbottom">Surgery</div>

                              <div
                                className="fv_new_table_right_textbottom"
                                dangerouslySetInnerHTML={{
                                  __html: data.planInfo.filter(res => res.idcardField === 'Surgery')[0].fieldValue
                                }}
                                style={{ marginLeft: '12%' }}
                              ></div>
                            </div>
                            <div className="fv_right_new_tablebottom">
                              <div className="fv_new_table_left_textbottom" style={{ flex: '0 0 auto' }}>
                                Hospital R&B
                              </div>

                              <div className="fv_new_table_right_textbottom" style={{ marginLeft: '2%' }}>
                                {' '}
                                {data.planInfo.filter(res => res.idcardField === 'Hospital R&B')[0].fieldValue}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                </div>
              ) : (
                <div>
                  {this.state.digitalcarddata &&
                    this.state.digitalcarddata.memberIdCardList.map((data, index) => (
                      <div>
                        {data.idcardField != 'group' &&
                        data.idcardField != 'prefix' &&
                        data.idcardField != 'card id' &&
                        data.idcardField != 'Chiropractic' &&
                        data.idcardField != 'Surgery EasyShare Limit per surgery' &&
                        data.idcardField != 'contact number' &&
                        data.idcardField != 'Program Details' &&
                        index == 0 ? (
                          <div>
                             {data.planInfo.filter(res => res.idcardField === 'Telemedicine')[0]&&
                             <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">Telemedicine</div>

                             
                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField === 'Telemedicine')[0].fieldValue}
                              </div>
                            </div>}
                            {data.planInfo.filter(res => res.idcardField === 'PCP Office Visit')[0] &&
                            <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">PCP Office Visit</div>

                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField === 'PCP Office Visit')[0].fieldValue}
                              </div>
                            </div>}
                           {(localStorage.getItem("planId")!=="10051" && localStorage.getItem("planId")!=="10052" && localStorage.getItem("planId")!=="10053")  && data.planInfo.filter(res => res.idcardField === 'Urgent Care')[0] && <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">Urgent Care</div>

                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField === 'Urgent Care')[0].fieldValue}
                              </div>
                            </div>}
                          {data.planInfo.filter(res => res.idcardField.toLowerCase() === 'specialty care')[0] &&  <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">Speciality Care</div>

                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField.toLowerCase() === 'specialty care')[0].fieldValue}
                              </div>
                            </div>}
                            {data.planInfo.filter(res => res.idcardField === 'ER Visit')[0] &&
                            <div className="fv_right_new_table">
                              <div className="fv_new_table_left_text">ER Visit</div>

                              <div className="fv_new_table_right_text">
                                {data.planInfo.filter(res => res.idcardField === 'ER Visit')[0].fieldValue}
                              </div>
                            </div>}
                          </div>
                        ) : null}
                      </div>
                    ))}
                </div>
              )}
              </div>}
            </div>
          </div>
          {/* =============Bttom  comntavt container========= */}
          {/* dangerouslySetInnerHTML={{ __html: data.fieldValue }} */}

          {(() => {
            var client_id_locale = localStorage.getItem('CLIENT_ID')
            if (localStorage.getItem("planId")=="10051" || localStorage.getItem("planId")=="10052" || localStorage.getItem("planId")=="10053" || localStorage.getItem("planId")!=="10061" || localStorage.getItem("planId")!=="10062" || localStorage.getItem("planId")!=="10063") {
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <div className="fv_center_details_text ">
                  Universal HealthShare programs from Universal Health Fellowship
                  are health care cost sharing ministry programs, not insurance.
                  Provider confirmations and questions (888) 366-6243
                </div>
                </div>
              )
            } else if (client_id_locale == '3211' || client_id_locale == '3098') {
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="fv_center_details_text ">
                  Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not
                  insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and
                  questions (888) 366-6243
                </div>
                </div>
              )
            } else if (client_id_locale == '5541' || client_id_locale == '4377') {
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="fv_center_details_text ">
                  Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not
                  insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and
                  questions (877) 987-1233
                </div>
                </div>
              )
            } else if (client_id_locale == '4350') {
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="fv_center_details_text ">
                  Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not
                  insurance. We are IN NETWORK for The Arizona Foundation for physician and ancillary services. Providers & Members
                  confirmations and questions (855) 229-0257
                </div>
                </div>
              )
            }else if (client_id_locale == '5503') {
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="fv_center_details_text ">
                Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. 
                We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations 
                and questions (888) 366-6243
                </div>
                </div>
              )
            } else if (this.state.network == 'Smartshare') {
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="fv_center_details_text ">
                  Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not
                  insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and
                  questions (888) 366-6243
                </div>
                </div>
              )
            } else {
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="fv_center_details_text ">
                  Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not
                  insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and
                  questions (888) 366-6243
                </div>
                </div>
              )
            }
          })()}
        </div>

        {/* <div class="fv_bottom">card-bottoom</div> */}
      </div>
    )
  }
}
