import React, { Component } from 'react'

export default class BackViewMoB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      network: this.props.network,
      isAdvancedHT: this.props.data && (this.props.data.memberIdCardList[0].planId=="10051" || this.props.data.memberIdCardList[0].planId=="10052" || this.props.data.memberIdCardList[0].planId=="10053" ||
      this.props.data.memberIdCardList[0].planId=="10061" || this.props.data.memberIdCardList[0].planId=="10062" || this.props.data.memberIdCardList[0].planId=="10063")
    }
    console.log('ID CARD BACK VIEW MOBILE==', this.props)
  }
  render() {
    return (
      !this.state.isAdvancedHT?<div class="bv">
        <div class="bv_details_text_div">
          {/* ========================Top Blue container 1st  =================== */}
          <div class="bv_bluetext_new_common">
            Discount Plan Organization: New Benefits, PO Box 803475, Dallas, TX 75380-3475, 800-800-7616
          </div>
          <div class="bv_bluetext_new_common">
            For other questions, call Universal HealthShare Customer Service: {this.props.contactNumber}
          </div>

          <div class="bv_ht_black_common_text">
            <span class="fv_ht_bold_text">Aetna Dental</span> provides discounts on dental procedures through a network of dental practice
            locations nationwide. Members present this card with payment in full of the discounted fee, for immediate savings when services
            are rendered. Actual costs and savings vary by provider, service and geographical area.{' '}
            <span class="fv_ht_underline_text">Call 855-647-6764</span> for more information or to locate dental network providers. This
            dental discount program is not available to VT residents.
          </div>

          <div class="bv_ht_black_common_text">
            <span class="fv_ht_bold_text">Coast to Coast VisionTM</span> has contracted with 20,000+ locations nationwide. Prescription
            glasses and contact lenses are discounted 20% to 60% in most cases. Eye exams and surgery are discounted 10% to 30% where
            available. LASIK discounts are available. This network includes ophthalmologists, optometrists, and national chain locations
            such as Pearle Vision, JCPenney Optical, Target Optical, LensCrafters, Visionworks and QualSight LASIK.{' '}
            <span class="fv_ht_underline_text">Call 855-647-6764</span> for more information or to locate vision network providers.
          </div>

          <div class="bv_ht_black_common_text">
            <span class="fv_ht_bold_text">FlexCare EAP</span> provides free unlimited, confidential telephone counseling services 24/7.{' '}
            <span class="fv_ht_underline_text">Call 866-697-8212</span> for more information or to speak with an EAP counselor.
          </div>

          
        </div>

        <div class="bottomview_image_and_no">
          <div class="bottom_link_backview">www.UniversalHealthFellowship.org</div>

          <div class="bv_top_image_container">
            <img src={require('../../Images/Card/thank-you-for.png')} class="bv_thank_image" />
            <img src={require('../../Images/Card/sharing.png')} class="bv_sharing_image" />
          </div>

          <div class="bv_card_no_bottom">UHFHTR050322E050122</div>
        </div>

        
      </div>:
    <div class="bv">
    <div class="bv_details_text_div" style={{padding:"8px 4px"}}>
      {/* ========================Top Blue container 1st  =================== */}
      
      
        
                <div>
                  <div class="bv_bluetext_common" style={{textAlign:"center", fontSize:"1.5vw"}}>
                  Discount Plan Organization: New Benefits, PO Box 803475, Dallas, TX 75380-3475, 800-800-7616
                  </div>
                
                
                    <div style={{textAlign:"center", marginTop:4}}>
                      <div class="bv_bluetext_common"  style={{textAlign:"center", marginTop:2, fontSize:"1.5vw"}}>
                      Use this Advanced Health Tools card only to access the services below.
                      </div>
                      <div class="bv_bluetext_common" style={{fontSize:"1.5vw"}}>
                      For sharing medical expenses, present your UHS Membership Card to your provider.
                      </div>
                      <div class="bv_bluetext_common"  style={{fontSize:"1.5vw"}}>
                      Questions? Call UHS Customer Service: 877-987-1233.
                      </div>
                    </div>

              
                </div>
              
              

        

      
        
                <div class="bv_second_container" style={{display:"flex"}}>
                  
                  <div className="bv_blacktext_common_div_left" style={{width:'70%',padding:"0px 30px 0px 0px"}}>
                  <div class="bv_blacktext_common" >
                  <strong>Aetna Dental</strong> provides discounts on dental procedures 
    through a network of dental practice locations nationwide. 
    Members present this card with payment in full of the 
    discounted fee, for immediate savings when services are 
    rendered. Actual costs and savings vary by provider, service 
    and geographical area. <u>Call 855-647-6764</u> for more info or to 
    locate dental network providers. Not available to VT residents.
                  
                  </div>
                  <div class="bv_blacktext_common" style={{marginTop:4}}>
                  <strong>Coast to Coast Vision™ </strong>has contracted with 20,000+ 
    locations nationwide. Prescription glasses and contact lenses 
    are discounted 20% to 60% in most cases. Eye exams and 
    surgery are discounted 10% to 30% where available. LASIK 
    discounts are available. This network includes ophthalmologists, 
    optometrists, and national chain locations such as Pearle 
    Vision, JCPenney Optical, Target Optical, LensCrafters, 
    Visionworks and QualSight LASIK. <u>Call 855-647-6764</u> for 
    more details or to locate vision network providers.
                  </div>
                  </div>
                  
                  <div className="bv_blacktext_common_div_right" style={{width:'50%'}}>
                  <div class="bv_blacktext_common ">
                  <strong>FlexCare EAP</strong>  provides free unlimited, confidential telephone 
    counseling services 24/7. <u>Call 866-697-8212</u> for more information 
    or to speak with an EAP counselor..
                  
                  </div>
                  <div class="bv_blacktext_common " style={{marginTop:4}}>
                  <strong>FlexCare On-demand Care </strong> provides virtual access to physicians 
    for on-demand care needs via phone, video, or mobile app. 
    Physicians can diagnose, treat, and prescribe medication, when 
    appropriate, for many medical issues. <u>Call 866-697-8212</u> to get 
    connected.
                  </div>
                  <div class="bv_blacktext_common " style={{marginTop:4}}>
                  <strong>CuramLife Concierge service</strong> will help reduce fees for eligible 
    hospital visits through hospital financial assistance programs and 
    also secure Good Faith estimates and hospital comparisons to 
    achieve the best self-pay outcome possible. <u>Call 888-282-8726 </u>
    to speak with a CuramLife Concierge Team member.
                  </div>
                  </div>
                  </div>
                
                    
                  
                      

        
            
                
                        
      

                    
              


      
    </div>



    <div class="bottomview_image_and_no">
          <div class="bottom_link_backview">www.UniversalHealthFellowship.org</div>

          <div class="bv_top_image_container">
            <img src={require('../../Images/Card/thank-you-for.png')} class="bv_thank_image" />
            <img src={require('../../Images/Card/sharing.png')} class="bv_sharing_image" />
          </div>

          <div class="bv_card_no_bottom">UHFAHTR102522E110122</div>
        </div>


    </div>
    )
  }
}
