import CryptoJS from 'crypto-js'
import React, { Component } from 'react'
import { getsharingguidlineslink, getWelcomeBooklet, getProgramInfo, getDocumentData } from '../ApiCall'
import CommonLoader from '../CommonLoader'
export default class MobileDocuments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentmessage: '',
      loader: false,
      disableWelcome:false,
      disableSharing:false,
      documentData:[]
    }
    this.gotoDoc = this.gotoDoc.bind(this)
  }
  componentDidMount(){
    this.getDocumentList()
    getWelcomeBooklet().then(res => {
      console.log('Welcome booklet=======', res)
      if (res.data && res.data.length > 0) {
        localStorage.removeItem("disableWelcome")
      }else{
        this.setState({disableWelcome:true})
        localStorage.setItem("disableWelcome", true)
      }
    })
    getsharingguidlineslink().then(res => {
      console.log('Sharing Guidlines', res)
      if (res.data && res.data.length > 0) {
        localStorage.removeItem("disableSharing")
      }else{
        this.setState({disableSharing:true})
        localStorage.setItem("disableSharing", true)
      }
    })

    getProgramInfo()
      .then(res => {
        if (res.data && res.data.programInfo) {
          var str = res.data.programInfo.programName
          if (str.includes('+')) {
            var str1 = res.data.programInfo.programName
            var res1 = str1.split(' ')
            this.setState({ programname: res1[0], programname2: ' with ' + res1[2], showwithacsm: true }, () => this.props.isLoader(false))
          } else {
            this.setState({ programname: res.data.programInfo.programName, showwithacsm: false }, () => this.props.isLoader(false))
          }
        } else {
          this.setState({ loader: false }, () => this.props.isLoader(false))
        }
      })
      .catch()
  }
  gotoDoc = () => {
    this.props.history.push('/DocumentsScreen')
  }

  openBrochure = (planName) => {
    if (planName == 'UHS1' || planName == 'UHS2' || planName == 'UHS3' || planName == 'UHS4' || planName == 'UHS5' || planName == 'UHS6') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf")
    } else if (planName == 'UHS SmartShare') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-SmartShare.pdf")
    } else if (planName == 'THS50' || planName == 'THS75' || planName == 'THS100') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Thrive-Program-Grid.pdf")
    } else if (planName == 'HL200' || planName == 'HL400' || planName == 'HL600') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf")
    } else if (planName == 'HLC200' || planName == 'HLC400' || planName == 'HLC600') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf")
    } else if (planName == 'ES1' || planName == 'ES2' || planName == 'ES3') {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-EssentialShare-Program-Grid.pdf")
    } else if (planName == 'CHS200' || planName == 'CHS400' || planName == 'CHS600' ) {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Community-HealthShare-Program-Grid.pdf")
    } else if (planName == 'PRS50' || planName == 'PRS75' || planName == 'PRS100' ) {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Prosper-Program-Brochure-Grid.pdf")
    }
  }

  openwelcome() {
    getWelcomeBooklet().then(res => {
      if (res.data && res.data.length > 0) {
        let providerLink = res.data[0].fieldValue
        let url = ''
        url = providerLink
        window.location.href = providerLink
      }
    })
  }

  opensecond() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf'
    )
  }

  openthird() {
    window.open(
      ' https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Statement-of-Shared-Faith-%26-Beliefs.pdf'
    )
  }

  getstartedHealth() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+How+do+I+Get+Started+with+Health+Sharing.pdf'
    )
  }

  getPrimaryCare() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Finding-Your-Primary-Care-Practitioner.pdf'
    )
  }

  open5question() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf'
    )
  }

  tipsTelemedicin() {
    window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Tips+on+Telemedicine.pdf')
  }

  great4reason() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+4+Great+Reasons+to+share+Universal+HealthShare+with+Your+Friends.pdf'
    )
  }

  quick3facts() {
    window.open(
      'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Doctors!+3+Quick+Facts+about+Health+Sharing.pdf'
    )
  }
  PharmacyVaccine=()=>{
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Pharmacy-Vaccine-Reimbursement-Transmittal.pdf")
  }
  TransmittalForm=()=>{
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Submitted-Needs-Transmittal-Form.pdf")
  }
  opensharingguidlines() {
    getsharingguidlineslink().then(res => {
      console.log('Sharing Guidlines', res)
      if (res.data && res.data.length > 0) {
        console.log('Shating guidlines response')
        let providerLink = res.data[0].fieldValue
        let url = ''
        url = providerLink
        window.location.href = providerLink
      }
    })
  }

  getDocumentList =()=>{
    let plainId = localStorage.getItem('planId');
    let memberSSN = localStorage.getItem('memberSSN');
    getDocumentData(plainId,memberSSN).then(res => {
      console.log('getCardEnableData=====', res.data.response)
      this.setState({documentData:res.data});
    })
  }

  render() {
    return (
      <div class="mob_contactinfocard">
        {this.state.loader ? <CommonLoader /> : null}

        <div class="mob_myneeds_top_container">
          <img src={require('../Images/LeftDrawer Icon/documents-icon-active.svg')} class="mob_myneeds_header_image" />
          <div class="mob_myneeds_header_text">Documents</div>
        </div>

        {this.props.documentCardData && this.props.documentCardData.enable ? (
          <div className="">
            {/* <div className="mob_documents_infomiddiv text-left">
              <div className={this.state.disableWelcome?"mob_documentscardnew_welcome_divDisabled":"mob_documentscardnew_welcome_div" }onClick={() => this.openwelcome()}>
                Welcome Booklet
              </div>
              {(this.state.programname == 'HLC200' || this.state.programname == 'HLC400' || this.state.programname == 'HLC600') ?
                null :
                <div className="mob_documentscardnew_welcome_div" onClick={() => this.openBrochure(this.state.programname)}>
                  Brochure Grid
                </div>
              }
              <div className={this.state.disableSharing?"mob_documentscardnew_welcome_divDisabled":"mob_documentscardnew_welcome_div"} onClick={() => this.opensharingguidlines()}>
                Sharing Guidelines
              </div>
              <div className="mob_documentscardnew_welcome_div" onClick={() => this.opensecond()}>
                Member Responsibilities
              </div>
              <div className="mob_documentscardnew_welcome_div" onClick={() => this.openthird()}>
                Statement of Shared Faith and Beliefs
              </div>

              <div className="mob_documentscardnew_welcome_div" onClick={() => this.getstartedHealth()}>
                Get Started with Health Sharing
              </div>
              <div className="documentscardnew_welcome_div" onClick={() => this.getPrimaryCare()}>
              UHS Finding Your Primary Care Practitioner
              </div>
              <div className="mob_documentscardnew_welcome_div" onClick={() => this.open5question()}>
                5 Questions about Health Sharing
              </div>
             { <div className="mob_documentscardnew_welcome_div" onClick={() => this.tipsTelemedicin()}>
                Tips on Telemedicine
              </div>}
              <div className="mob_documentscardnew_welcome_div" onClick={() => this.great4reason()}>
                4 Great Reasons to share UHS
              </div>
              <div className="mob_documentscardnew_welcome_div" onClick={() => this.quick3facts()}>
                3 Quick Facts for Doctors
              </div>
              <div className="mob_documentscardnew_welcome_div" onClick={() => this.TransmittalForm()}>
              Submitted Needs Transmittal Form
              </div>
              <div className="mob_documentscardnew_welcome_div" onClick={() => this.PharmacyVaccine()}>
              Pharmacy Vaccine Reimbursement Transmittal
              </div>
            </div> */}
            <div className="mob_documents_infomiddiv text-left">
          {this.state.documentData?.map((value,index)=>
            <div className="mob_documentscardnew_welcome_div" key={index} onClick={()=>window.open(value.document)}>
              {value.description}
            </div>
           )
          }
        </div>
          </div>
        ) : this.props.documentCardData != null ? (
          <div style={{ padding: '35px' }}>
            <div className="tempModalTxt">
              We’re facing some technical difficulties, due to which this feature is currently unavailable. For support, call Member
              Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to 8.00pm CST.
            </div>
          </div>
        ) : null}
      </div>
    )
  }
  openAskCaryn = () => {
    let urlData = {
      memberId: localStorage.getItem('Member_EMPID'),
      query: this.state.documentmessage,
      ChannelUId: 'memberportal'
    }
    let key = CryptoJS.enc.Utf8.parse('000102030405060708090a0b0c0d0e0f')
    let iv = CryptoJS.enc.Utf8.parse('4t7w9z$C&F)J@NcR')
    let input = CryptoJS.enc.Utf8.parse(JSON.stringify(urlData))
    var dataEncrypt = CryptoJS.AES.encrypt(input, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString()
    window.open(process.env.REACT_APP_AIKB+`/?isnav=true&data=${encodeURIComponent(dataEncrypt)}`)
  }
}
