import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import firebase from '../../../firebase'
import { getAnnouncemetDetails, getEducationDetails, getNotificationDetails } from '../../ApiCall'
import CommonLoader from '../../CommonLoader'
import AnnouncementNotification from '../../Images/notification/notification_announcement_icon.svg'
import NoticeIcon from '../../Images/notification/notification_notice_icon.svg'
import ChatIcon from '../../WebScreen/ChatBox/ChatIcon'
import MobCopyright from '../MobCopyright'
import MobileFooter from '../MobileFooter'
import MobileHeader from '../MobileHeader'
import VideoIcon from '../../Images/LeftDrawer Icon/education_video_icon.svg'
import BlogIcon from '../../Images/LeftDrawer Icon/education_blog_icon.svg'

export default class AnnouncementMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataUnread: [],
            dataRead: [],
            data: [],
            message: null,
            loader: false,
            date: '',
            page: 0,
            limit: 20,
            rowCount: 0,
            loadMoreBtnshow: false,
            noticeModal: false,
            content: '',
            header: '',
            publishDate: '',
            URL: null,
            Type: null,
            videoUrl: null,
            isShowModal: false,
        }
    }
    componentDidMount() {
        this.getAnnouncement()
        const msg = firebase.messaging.isSupported() ? firebase.messaging() : null
        if (msg) {
            msg.onMessage(payload => {
                this.getAnnouncement()
                // const myEvent = new CustomEvent("myevent",{
                //   detail: {},
                //   bubbles: true,
                //   cancelable: true,
                //   composed: false,
                // })
                // document.dispatchEvent(myEvent);
                // console.log("onMessage:+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++home ", payload);
            })
        }
    }
    getAnnouncement = () => {
        this.setState({
            loader: true
        })
        getEducationDetails(this.state.page).then(res => {
            if (res.data && res.data.response) {
                //    if(res.data.response.length > 0){
                if (res.data.response.pageList > 20) {
                    this.setState({ loadMoreBtnshow: true })
                } else {
                    this.setState({ loadMoreBtnshow: false })
                }
                // }
                this.setState({
                    data: res.data.response.notificationDetails,
                    rowCount: res.data.response.notificationDetails.length,
                    loader: false,
                    page: 1
                })
            } else {
                this.setState({ message: 'Notification not available !', loader: false })
            }
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ loader: true })
        getEducationDetails(this.state.page).then(res => {
            var resnew = res.data.response.notificationDetails
            // var data = this.state.data;
            // var newdata = data.concat(resnew);
            if (this.state.data.length <= this.state.rowCount) {
                this.setState({ page: this.state.page + 1, data: [...this.state.data, ...resnew], loader: false })
            }
            if (this.state.data.length == this.state.rowCount) {
                this.setState({ loadMoreBtnshow: false })
            } else {
                this.setState({ loadMoreBtnshow: true })
            }
        })
    }

    onSectionClick = (title, url) => {
        switch (title) {
            case "BLOG POST":
                window.open(url)
                break;
            case "VIDEO":
                this.setState({ videoUrl: url, isShowModal: true })
                break;
            default:
                break;
        }
    }

    getDateInUTC = (date, getInMillisecs) => {
        if (date) {
            let newDateTime = new Date(date)

            return new Date(newDateTime)
        }

        return date
    }

    dateformat(date) {
        const enrollmentDate = this.getDateInUTC(date, true)
        let nowdate = this.getDateInUTC(new Date(), true)

        var day = moment(enrollmentDate).format('DD')

        var mon = moment(enrollmentDate).format('MM')

        var year = moment(enrollmentDate).format('YYYY')

        var date = mon + '/' + day + '/' + year

        var todayDate = moment(nowdate).format('MM/DD/YYYY')
        let hr = moment(enrollmentDate).format('hh')

        if (date !== todayDate) {
            return moment(date).format('MMM DD, YYYY')
        } else {
            if (hr == '00') {
                return moment(enrollmentDate).format('m') + 'mins'
            }
            if (hr > 12) {
                return moment(enrollmentDate).format('hh:mm A')
            } else {
                return moment(enrollmentDate).format('hh:mm A')
            }
        }
    }

    publishDateformat(date) {
        const enrollmentDate = this.getDateInUTC(date, true)
        let nowdate = this.getDateInUTC(new Date(), true)

        var day = moment(enrollmentDate).format('DD')

        var mon = moment(enrollmentDate).format('MM')

        var year = moment(enrollmentDate).format('YYYY')

        var date = mon + '/' + day + '/' + year

        var todayDate = moment(nowdate).format('MM/DD/YYYY')
        let hr = moment(enrollmentDate).format('hh')

        return moment(date).format('MMMM DD, YYYY')
    }

    onClickUpdateNotificationStatus = (title, msg, url, type, date) => {
        console.log('onClickUpdateNotificationStatus====', title, url, msg, date)
        this.setState({
            noticeModal: true,
            content: msg,
            header: title,
            publishDate: date,
            URL: url,
            Type: type
        })
    }

    goBack = () => {
        this.props.history.push('/')
    }
    render() {
        return (
            <div>
                {this.state.loader ? <CommonLoader /> : null}

                <MobileHeader name="Health and Wellness Education" />

                <div className="notificationListWrapper">
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                {this.state.data[0] ? (
                                    this.state.data.map((data, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell class="notification_details_mainDiv">
                                                    <>
                                                        <div>
                                                            {
                                                                data.title == 'BLOG POST' ?
                                                                    <img src={BlogIcon} class="Notification_category_img" />
                                                                    : data.title == 'VIDEO' ?
                                                                        <img src={VideoIcon} class="Notification_category_img" />
                                                                        : null
                                                            }
                                                        </div>
                                                        <div
                                                            class="notification_category_Message_details"
                                                            onClick={() => this.onSectionClick(data.title, data.urlPath)}
                                                        >
                                                            <p class="notification_category_label_details">
                                                                {data.title}
                                                                <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>
                                                            </p>
                                                            <div className="notification_details_message">{data.message}</div>
                                                        </div>
                                                    </>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                ) : (
                                    <div className="data_not_found" style={{ height: '90vh' }}>
                                        <p class="noneeds_msg_display" style={{ textAlign: 'center' }}>
                                            Watch this space for upcoming articles and videos on health and wellness.
                                        </p>
                                    </div>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.loadMoreBtnshow ? (
                        <div className="loadMoreWrapper_notification" onClick={() => this.handleChangePage()}>
                            <button className="loadMoreBtn_notification">Load More</button>
                        </div>
                    ) : null}
                </div>

                <div style={{ bottom: "9vh", position: 'relative' }}>
                    <ChatIcon showAIChatIcon={true} />
                    <ChatIcon />
                </div>
                <div style={{ bottom: "9vh", position: 'relative' }}>
                    <MobCopyright />
                </div>
                <div class="fixed-bottom">
                    <MobileFooter />
                </div>

                <Modal size='xl' show={this.state.isShowModal} centered className="ChangPayModal paymentWalletModal" backdrop="static">
                    <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: 'nowrap' }}>
                            <span class="Change-Payment-Method" style={{ lineHeight: '1.3' }}>
                                Video Title
                            </span>
                            <IconButton aria-label="close" onClick={() => this.setState({ isShowModal: false })} style={{ marginTop: '-13px' }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <div>
                            <iframe
                                src={this.state.videoUrl}
                                title="Common Module"
                                onLoad={() => {
                                    this.setState({
                                        isShowModal: true
                                    })
                                }}
                                style={{
                                    width: '725px',
                                    height: '470px',
                                    border: 'none',
                                    margin: 'auto'
                                }}
                            ></iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
