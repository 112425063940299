import React, { Component } from 'react'
import { getAgentInfo, getContactData, gethealthcard, getNetworkName, getSendNeedsToContactAddress, getSourceID } from '../ApiCall'

export default class MobileContactInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      digitalheathcard: null,
      plainId: null,
      network: null,
      provider: null,
      channel: null,
      contactNumber: null,
      cardId: null,
      showEmpId: false,
      agentemail: null,
      agentname: null,
      agentno: null,
      loader: false
    }
  }

  async gethealthcarddata() {
    gethealthcard().then(res => {
      //   console.log('Documents card server SERVER RESPONSE Health Card=', res.data)
      if (res.data.memberIdCardList != null) {
        this.setState({ digitalheathcard: res.data, plainId: res.data.memberIdCardList[0].planId })
        // this.sethealthcarddata()
        this.getNetworkData(res.data.memberIdCardList[0].planId)
        this.setContactandCardID(res.data)
      } else {
        // alert('Data not available.')
        // this.setState({ alertflag: true, loader: false })
      }
    })
  }

  setContactandCardID(data) {
    data.memberIdCardList[0].planInfo.map((data, index) => {
      if (data.idcardField == 'contact number') {
        this.setState({ contactNumber: data.fieldValue })
      }
      if (data.idcardField == 'card id') {
        this.setState({ cardId: data.fieldValue })
      }
    })
  }

  getNetworkData(plainid) {
    getNetworkName(plainid).then(res => {
      console.log('netowrok name againt plain id=======', res.data)
      this.setState({
        network: (res.data.provider_network === 'HLC' || res.data.provider_network === 'THS') ? 'PHCS' : res.data.provider_network,
        provider: res.data.provider_network
      })
      this.setState({
        // showHealthCard: true,
        // visible: true,
        loader: false
      })
    })
  }

  componentDidMount() {
    // this.gethealthcarddata()
    // this.getSourceIDbyemail()
    this.getContactInfoData();
  }

  getContactInfoData(){
    let plainId = localStorage.getItem('planId');
    let sourceid = localStorage.getItem('sourceid');
    let member_ssn = localStorage.getItem('memberSSN');
    getContactData(plainId,sourceid,member_ssn).then(
      res =>{
        console.log( res?.data?.response+" -----------");
        this.setState({contactData : res?.data?.response})
      }
    )
  }

  openwelcome() {
    window.open(
      'https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/AFA/UHS+Welcome+Booklet+-+6kAFAUHSR0626E060920.pdf'
    )
  }

  opensecond() {
    window.open(
      'https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf'
    )
  }

  openthird() {
    window.open(
      'https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF+Statement+of+Faith+%26+Beliefs.pdf'
    )
  }

  getSourceIDbyemail() {
    getSourceID().then(res => {
      console.log('Member source id issss in contact cardd', res.data.memberIdSource)
      this.agentInfoget(res.data.memberIdSource)
    })
  }

  agentInfoget(sourceid) {
    if (sourceid) {
      getAgentInfo(sourceid).then(res => {
        console.log('agent info get========', res)
        console.log('agent info get========', res.data.response)
        if (res.data.response) {
          localStorage.setItem('AgentDetails', JSON.stringify(res.data.response))
          this.setState({
            agentemail: res.data.response.email,
            agentname: res.data.response.name,
            agentno: res.data.response.phone
          })
          // alert("if call")
        } else {
          // alert("else call")
        }
      })
    }
  }

  getTelNumber = () => {
    let phoneNumber = ''
    phoneNumber = 'tel:' + this.state.contactNumber
    document.location.href = 'tel:' + this.state.contactNumber
  }

  getAgentTelNumber = () => {
    let phoneNumber = ''
    phoneNumber = 'tel:' + this.state.agentno
    document.location.href = 'tel:' + this.state.agentno
  }

  // agentInfoget() {
  //   getAgentInfo().then(res => {
  //     console.log('agent info get========', res)
  //     console.log('agent info get========', res.data.response)
  //     if(res.data.response){
  //       this.setState({agentemail:res.data.response.email,agentname:res.data.response.name,agentno:res.data.response.phone,})
  //       // alert("if call")
  //     }else{
  //       // alert("else call")
  //     }

  //   })
  // }
  render() {
    return (
      <div class="mob_contactinfocard">
        <div class="mob_myneeds_top_container">
          <img src={require('../Images/LeftDrawer Icon/contact-info-icon-active.svg')} class="mob_myneeds_header_image" />
          <div class="mob_myneeds_header_text">Contact Information</div>
          {/* < BsThreeDotsVertical   color="#4f4f4f" class="mob_card_threedot" /> */}
        </div>

        {this.props.contactCardData && this.props.contactCardData.enable ? (
          // (() => {
          //   let client_id = localStorage.getItem('CLIENT_ID')
          //   switch (this.state.network) {
          //     case 'PHCS':
          //       if (client_id == '5541' || client_id == '4377') {
          //         return (
          //           <div class="">
          //             <div class="mob_contact_infomiddiv text-left">
          //               {this.state.contactNumber ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">For Pre-notification or Customer Service call: </div>
          //                   <div class="mob_contactinfo_Value">(877) 987-1233</div>
          //                 </>
          //               ) : null}
          //           {<div>
          //               <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //               <div class="mob_contactinfo_Value">1 (866) 697-8212</div>
          //           </div>}
          //               <div class="mob_contactinfo_label">Send needs to:</div>
          //               <div class="mob_contactinfo_Value">
          //                 {this.state.provider === 'HLC'
          //                   ? '4555 Mansell Road, Suite 300 Alpharetta, GA 30022'
          //                   : getSendNeedsToContactAddress()}
          //               </div>

          //               {this.state.agentname ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //                 </>
          //               ) : null}
          //             </div>
          //           </div>
          //         )
          //       } else if (client_id == '3211' || client_id == '3098') {
          //         return (
          //           <div class="">
          //             <div class="mob_contact_infomiddiv text-left">
          //               {this.state.contactNumber ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">For Pre-notification or Customer Service call: </div>
          //                   <div class="mob_contactinfo_Value">(888) 366-6243</div>
          //                 </>
          //               ) : null}
          //             { <div>
          //               <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //               <div class="mob_contactinfo_Value">(866) 697-8212</div> 
          //             </div>}
          //               <div class="mob_contactinfo_label">Send needs to:</div>
          //               <div class="mob_contactinfo_Value">
          //                 {this.state.provider === 'HLC'
          //                   ? '4555 Mansell Road, Suite 300 Alpharetta, GA 30022'
          //                   : getSendNeedsToContactAddress()}
          //               </div>

          //               {this.state.agentname ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //                 </>
          //               ) : null}
          //             </div>
          //           </div>
          //         )
          //       } else if (
          //         client_id == '5558' ||
          //         client_id == '4386' ||
          //         client_id == '5696' ||
          //         client_id == '4397' ||
          //         client_id == '4360' ||
          //         client_id == '4357' ||
          //         client_id == '4356' ||
          //         client_id == '4359' ||
          //         client_id == '4364' ||
          //         client_id == '4378' ||
          //         client_id == '4379' ||
          //         client_id == '4387' ||
          //         client_id == '5448' ||
          //         client_id == '5447' ||
          //         client_id == '5446' ||
          //         client_id == '5450' ||
          //         client_id == '5503' ||
          //         client_id == '5550' ||
          //         client_id == '5553' ||
          //         client_id == '5559'||
          //         client_id=="5540"||
          //         client_id == '5503' ||
          //         client_id=="4376"
          //       ) {
          //         return (
          //           <div class="">
          //             <div class="mob_contact_infomiddiv text-left">
          //               {this.state.contactNumber ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">For Pre-notification: </div>
          //                   <div class="mob_contactinfo_Value">(888) 366-6243</div>
          //                   <div class="mob_contactinfo_label">For Customer Service call: </div>
          //                   <div class="mob_contactinfo_Value">1 (877) 987-1233</div>
          //                 </>
          //               ) : null}
          //               { <div>
          //               <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //               <div class="mob_contactinfo_Value">1 (866) 697-8212</div>
          //             </div>}
          //               <div class="mob_contactinfo_label">Send needs to:</div>
          //               <div class="mob_contactinfo_Value">P.O. Box 211223, Eagan, MN 55121</div>

          //               {this.state.agentname ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //                 </>
          //               ) : null}
          //             </div>
          //           </div>
          //         )
          //       } else {
          //         return (
          //           <div class="">
          //             <div class="mob_contact_infomiddiv text-left">
          //               {this.state.contactNumber ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">For Pre-notification or Customer Service call: </div>
          //                   <div class="mob_contactinfo_Value">{this.state.contactNumber}</div>
          //                 </>
          //               ) : null}
          //               { <div>
          //               <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //               <div class="mob_contactinfo_Value">1 (888) 501-2405</div>
          //               </div>}
          //               <div class="mob_contactinfo_label">Send needs to:</div>
          //               <div class="mob_contactinfo_Value">
          //                 {this.state.provider === 'HLC'
          //                   ? '4555 Mansell Road, Suite 300 Alpharetta, GA 30022'
          //                   : getSendNeedsToContactAddress()}
          //               </div>

          //               {this.state.agentname ? (
          //                 <>
          //                   <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                   <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //                 </>
          //               ) : null}
          //             </div>
          //           </div>
          //         )
          //       }

          //     case 'Smartshare':
          //       return (
          //         <div class="">
          //           <div class="mob_contact_infomiddiv text-left">
          //             {this.state.contactNumber ? (
          //               <>
          //                 <div class="mob_contactinfo_label">For Pre-notification Customer Service call: </div>
          //                 <div class="mob_contactinfo_Value">{this.state.contactNumber}</div>
          //               </>
          //             ) : null}
          //               {<div>
          //             <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //             <div class="mob_contactinfo_Value">1 (866) 501-2405</div>
          //             </div>}
          //             <div class="mob_contactinfo_label">Send needs to:</div>
          //             <div class="mob_contactinfo_Value">P.O. Box 211223, Eagan, MN 55121</div>
          //             {this.state.agentname ? (
          //               <>
          //                 <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //               </>
          //             ) : null}
          //           </div>
          //         </div>
          //       )
          //     case 'smartshare25':
          //       return (
          //         <div class="">
          //           <div class="mob_contact_infomiddiv text-left">
          //             {this.state.contactNumber ? (
          //               <>
          //                 <div class="mob_contactinfo_label">For Pre-notification or Customer Service call: </div>
          //                 <div class="mob_contactinfo_Value">{this.state.contactNumber}</div>
          //               </>
          //             ) : null}
          //       { <div>
          //             <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //             <div class="mob_contactinfo_Value">1 (888) 501-2405</div>
          //           </div>}
          //             <div class="mob_contactinfo_label">Send needs to:</div>
          //             <div class="mob_contactinfo_Value">{getSendNeedsToContactAddress()}</div>

          //             {this.state.agentname ? (
          //               <>
          //                 <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //               </>
          //             ) : null}
          //           </div>
          //         </div>
          //       )
          //     case 'smartshare50':
          //       return (
          //         <div class="">
          //           <div class="mob_contact_infomiddiv text-left">
          //             {this.state.contactNumber ? (
          //               <>
          //                 <div class="mob_contactinfo_label">For Pre-notification or Customer Service call: </div>
          //                 <div class="mob_contactinfo_Value">{this.state.contactNumber}</div>
          //               </>
          //             ) : null}
          //         { <div>
          //             <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //             <div class="mob_contactinfo_Value">1 (888) 501-2405</div>
          //           </div>}
          //             <div class="mob_contactinfo_label">Send needs to:</div>
          //             <div class="mob_contactinfo_Value">{getSendNeedsToContactAddress()}</div>

          //             {this.state.agentname ? (
          //               <>
          //                 <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //               </>
          //             ) : null}
          //           </div>
          //         </div>
          //       )
          //     case 'healthyLife':
          //       return (
          //         <div class="">
          //           <div class="mob_contact_infomiddiv text-left">
          //             {this.state.contactNumber ? (
          //               <>
          //                 <div class="mob_contactinfo_label">For Pre-notification: </div>
          //                 <div class="mob_contactinfo_Value">{this.state.contactNumber}</div>
          //                 <div class="mob_contactinfo_label">For Customer Service call: </div>
          //                 <div class="mob_contactinfo_Value">1 (877) 987-1233</div>
          //               </>
          //             ) : null}
          //           {<div>
          //             <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //             <div class="mob_contactinfo_Value">1 (866) 697-8212</div>
          //           </div>}
          //             <div class="mob_contactinfo_label">Send needs to:</div>
          //             <div class="mob_contactinfo_Value">{getSendNeedsToContactAddress()}</div>

          //             {this.state.agentname ? (
          //               <>
          //                 <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //               </>
          //             ) : null}
          //           </div>
          //         </div>
          //       )
          //     case 'AFMC':
          //       return (
          //         <div class="">
          //           <div class="mob_contact_infomiddiv text-left">
          //             {this.state.contactNumber ? (
          //               <>
          //                 <div class="mob_contactinfo_label">For Pre-notification or Customer Service call: </div>
          //                 <div class="mob_contactinfo_Value">{this.state.contactNumber}</div>
          //               </>
          //             ) : null}
          //           { <div>
          //             <div class="mob_contactinfo_label ">For Telemedicine call:</div>
          //             <div class="mob_contactinfo_Value">1 (888) 501-2405</div>
          //           </div>}
          //             <div class="mob_contactinfo_label">Send needs to:</div>
          //             <div class="mob_contactinfo_Value">Arizona Foundation, P.O. Box 2909, Phoenix, AZ 85062-2909</div>

          //             {this.state.agentname ? (
          //               <>
          //                 <div class="mob_contactinfo_label">Your Agent Details:</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentname}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentno}</div>
          //                 <div class="mob_contactinfo_Value">{this.state.agentemail}</div>
          //               </>
          //             ) : null}
          //           </div>
          //         </div>
          //       )
          //   }
          // })()
          <div class="mob_contact_infomiddiv text-left">
              {
                this.state.contactData?.map((contact,key)=>
                <div key={key}>
                   <div class="mob_contactinfo_label">{contact.label}</div>
                 <div class="mob_contactinfo_Value">
                  {(contact?.label === 'Your Agent Details:') ?
                    <div dangerouslySetInnerHTML={{__html:`${JSON.parse(contact?.value)?.name}<br>\n${JSON.parse(contact?.value)?.phone.replace(/^\+1|^(\+91)/, '')}<br>\n${JSON.parse(contact?.value)?.email}` }}/>
                    : Array.isArray(contact.value) ? (
                      contact.value.map((nestedContact, nestedKey) => (
                        <div key={nestedKey} style={{marginLeft: 15}}>
                          <div className="contactinfo_label">{nestedContact.label}</div>
                          <div className="contactinfo_Value">{nestedContact.value}</div>
                        </div>
                      ))
                    ) : (
                      contact.value
                    )}
                 </div>
                 </div>
                )
              }
            </div>
        ) : this.props.contactCardData != null ? (
          <div style={{ padding: '35px' }}>
            <div className="tempModalTxt">
              We’re facing some technical difficulties, due to which this feature is currently unavailable. For support, call Member
              Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to 8.00pm CST.
            </div>
          </div>
        ) : null}

        {/* <div class="mob_contactcard_footer">
                    <div class="row">
                        <div class="col text-left">
                            <FaHeart color="rgba(0, 0, 0, 0.54)" size={16} />
                            <IoMdShare color="rgba(0, 0, 0, 0.54)" size={18} class="ml-4" />
                        </div>
                        <div class="col text-right">
                            <MdKeyboardArrowDown color="rgba(0, 0, 0, 0.54)" size={20} />
                        </div>
                    </div>
                </div> */}
      </div>
    )
  }
}
